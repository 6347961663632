import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import SearchPost from '../components/SearchPost'
import Sites from '../components/Sites'

const TipsJudiOnline = ({ data }) => {
  const posts = data.allMdx.edges
  const localSearchPosts = data.localSearchPosts
  return (
    <Layout>
      <Seo
        title={`Tips Judi Online Indonesia Gubelinlab`}
        description={`Tips Judi Online  Terbaik Yang Kami Racik Untuk Membantu Anda Memenangkan Permainan Judi Slot , Judi Bola , Judi Poker Maupun Judi Casino Online`}
        url={`https://gubelinlab.com/tips-judi-online/`}
        image={`https://gubelinlab.com/images/og_image.jpg`}
      />
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-8xl mx-auto">
          <div className="text-center">
            <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-rose-600 mb-3">
              Update Tips Judi Online Terbaru
            </h2>
            <p className="text-3xl sm:text-5xl lg:text-6xl leading-none font-extrabold text-gray-900 tracking-tight mb-8">
              Jangan Lewatkan <br />
              Tips Terbaru Dari{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-br from-pink-500 to-rose-500">
                Gubelinlab
              </span>
            </p>
            <p className="max-w-4xl text-lg sm:text-2xl font-medium sm:leading-10 space-y-6 mb-6 mx-auto">
              Update Tips Judi Online Terbaru Dan Terbaik Yang Secara Eksklusif Kami Racik Untuk
              Membantu Anda Memenangkan Permainan{' '}
              <strong>Judi Slot , Judi Bola , Judi Poker Maupun Judi Casino Online</strong>
            </p>
          </div>
          <SearchPost posts={posts} localSearchPosts={localSearchPosts} />
        </div>
      </div>
      <Sites />
    </Layout>
  )
}

export default TipsJudiOnline

export const pageQuery = graphql`
  query TipsJudiOnlinePageQuery {
    localSearchPosts {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___created_at], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            description
            created_at(formatString: "dddd, DD MMM YYYY", locale: "id")
            featured_image
            updated_at
            hashtags
          }
          fields {
            slug
          }
          id
          timeToRead
        }
      }
    }
  }
`
