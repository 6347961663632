import React, { useState } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import * as queryString from 'query-string'
import { navigate } from 'gatsby'
import { globalHistory } from '@reach/router'
import Logo from './Logo'
import LatestPosts from '../components/LatestPosts'

const Posts = ({ posts }) => (
  <>
    {posts.map(({ node }) => {
      const {
        fields: { slug },
        frontmatter: { title, description, featured_image, created_at, updated_at, hashtags },
        id,
        timeToRead,
      } = node
      return (
        <LatestPosts
          key={id}
          title={title}
          description={description}
          hashtags={hashtags}
          featuredImage={featured_image}
          slug={slug}
          date={created_at}
          time={updated_at}
          timeToRead={timeToRead}
        />
      )
    })}
  </>
)

const SearchResult = ({ results }) =>
  results.length > 0 ? (
    results.map((result) => {
      return (
        <LatestPosts
          key={result.id}
          title={result.title}
          description={result.description}
          hashtags={result.hashtags}
          featuredImage={result.featured_image}
          slug={result.slug}
          date={result.created_at}
          time={result.updated_at}
          timeToRead={result.timeToRead}
        />
      )
    })
  ) : (
    <div className="col-span-2 relative py-3">
      <div className="absolute inset-0 bg-gradient-to-r from-pink-500 to-rose-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-1 sm:rounded-3xl" />
      <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
        <Logo htmlClass={`h-12 w-auto mx-auto`} textColor={`#0f172a`} />
        <div className="text-center mt-6">
          <p className="max-w-4xl text-lg sm:text-2xl font-medium sm:leading-10 space-y-6 mb-6 mx-auto">
            Oops, Pencarian Anda Tidak Ditemukan! Silahkan Coba Dengan Kata Kunci Lain!
          </p>
        </div>
      </div>
    </div>
  )

const SearchPost = ({ posts, localSearchPosts }) => {
  const { search } = queryString.parse(globalHistory.location.search)
  const [query, setQuery] = useState(search || '')
  const results = useFlexSearch(query, localSearchPosts.index, localSearchPosts.store)

  return (
    <div>
      <div>
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            name="search"
            id="search"
            className="focus:ring-rose-500 focus:border-rose-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
            placeholder="Search Tips Judi Online"
            value={query}
            onChange={(e) => {
              navigate(
                e.target.value
                  ? `/tips-judi-online/?search=${e.target.value}`
                  : '/tips-judi-online/'
              )
              setQuery(e.target.value)
            }}
          />
        </div>
      </div>
      <div className="mt-12 max-w-lg mx-auto grid gap-8 lg:grid-cols-2 lg:max-w-none">
        {query ? <SearchResult results={results} /> : <Posts posts={posts} />}
      </div>
    </div>
  )
}

export default SearchPost
